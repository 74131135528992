<template>
  <div>
    <c-table
      ref="grid"
      title="결재이양 목록"
      tableId="grid"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :filtering="false"
      :columns="grid.columns"
      :data="grid.data"
      selection="single"
      rowKey="sysApprovalTransferId"
      :isExcelDown="false"
      :editable="editable"
      @table-data-change="tableDataChange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLADD" icon="add" @btnClicked="addTransfer" v-if="editable" />
          <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeTransfer" v-if="editable" />
          <c-btn label="LBLSAVE" icon="save" @btnClicked="saveTransfer" v-if="editable" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'appr-transfer',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'transferUserDeptName',
            field: 'transferUserDeptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:250px',
          },
          {
            name: 'transferUserName',
            field: 'transferUserName',
            label: '이양자 명',
            align: 'center',
            sortable: false,
          },
          {
            name: 'transferUserJobName',
            field: 'transferUserJobName',
            label: '직책',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'transferUserSpotName',
            field: 'transferUserSpotName',
            label: '직위',
            align: 'center',
            sortable: false,
            style: 'width:150px',
          },
          {
            name: 'foreverFlag',
            field: 'foreverFlag',
            label: '기한 제한없음',
            type: 'check',
            true: 'Y',
            false: 'N',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'transferStartDt',
            field: 'transferStartDt',
            label: '결재이양 시작일자',
            align: 'center',
            style: 'width:300px',
            type: 'date',
            disableTarget: 'foreverFlag',
            disableCon: 'N',
            sortable: false,
          },
          {
            name: 'transferEndDt',
            field: 'transferEndDt',
            label: '결재이양 종료일자',
            align: 'center',
            style: 'width:300px',
            type: 'date',
            disableTarget: 'foreverFlag',
            disableCon: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sys.appr.transfer.list.url
      this.saveUrl = transactionConfig.sys.appr.transfer.insert.url
      this.deleteUrl = transactionConfig.sys.appr.transfer.delete.url
      this.getList()
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {userId: this.$store.getters.user.userId};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addTransfer() {
      this.popupOptions.title = '사용자 검색'; // 사용자 검색
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.grid.data, { transferUserId: item.userId }) === -1) {
            this.grid.data.push({
              sysApprovalTransferId: uid(),  // 결재이양자 일련번호
              userId: this.$store.getters.user.userId,  // 사용자 ID
              transferUserId: item.userId,  // 결재이양자
              transferUserName: item.userName,
              transferUserDeptName: item.deptName,
              transferUserJobName: item.jobName,
              transferUserSpotName: item.spotName,
              foreverFlag: 'N',  // 무제한여부
              transferStartDt: '',  // 결재이양 시작일자
              transferEndDt: '',  // 결재이양 종료일자
              editFlag: 'C'
            })
          }
        })
      }
    },
    removeTransfer() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getList()
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveTransfer() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      let isValidTransfer = false;
      this.$_.forEach(saveData, _item => {
        if (_item.foreverFlag == 'N' && !_item.transferStartDt && !_item.transferEndDt) {
          isValidTransfer = true;
        }
      });
      if (isValidTransfer) {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '기간 제한일 경우 시작일자/종료일자를 입력하세요.', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
          type: 'warning', // success / info / warning / error
        });
      } else {
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList()
                window.getApp.$emit('ALERT', {
                  title: '안내 ', /* 안내 */
                  message: '저장되었습니다.', /* 저장되었습니다. */
                  type: 'success', // success / info / warning / error
                });
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    tableDataChange(props) {
      if (props.row['foreverFlag'] == 'Y') {
        this.$set(props.row, 'transferStartDt', '');
        this.$set(props.row, 'transferEndDt', '');
      }
    },
  }
};
</script>
